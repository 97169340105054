import { ConexionNavisionControllerApi, ProductosPorRangoControllerApi, TiposRangoControllerApi, ClubsControllerApi, SustitutosControllerApi, settings} from '@api/backend'
import { displaySuccessMsg } from '@src/utility/Utils'

const prefix = 'productosPorRango'

export const GET_PRODUCTO_POR_RANGO = `${prefix}/GET_DATA_productoPorRango`
export const GET_PRODUCTOS_POR_RANGO = `${prefix}/GET_DATA`
export const GET_PRODUCTOS_POR_RANGO_ALL = `${prefix}/GET_DATA_ALL`
export const GET_PRODUCTOS_POR_RANGO_COUNT = `${prefix}/GET_DATA_COUNT`
export const ADD_PRODUCTO_POR_RANGO = `${prefix}/ADD`
export const DELETE_PRODUCTO_POR_RANGO = `${prefix}/DELETE`
export const UPDATE_PRODUCTO_POR_RANGO = `${prefix}/UPDATE`
export const GET_FILTROS_PRODUCTOS_POR_RANGO = `${prefix}/GET_FILTROS_PRODUCTOS_POR_RANGO`
export const INIT_DATA_NECESARIA = `${prefix}/INIT_DATA_NECESARIA`

const apiProductosPorRango = new ProductosPorRangoControllerApi(settings)
const apiTiposRango = new TiposRangoControllerApi(settings)
const apiClubs = new ClubsControllerApi(settings)
const apiSutitutos = new SustitutosControllerApi(settings)
const apiNavisionNew = new ConexionNavisionControllerApi(settings)

export const getProductosPorRango = params => {
  return async (dispatch, getState) => {
    try {
      const { page = 1, perPage = 10, filtrosBusqueda } = params
      const { skuProducto, tipoDeRango, club, desdeFecha, hastaFecha } = filtrosBusqueda || {}
      const inicio = ((page - 1) * perPage)
      const fin = inicio + perPage

      // const empresaId = getState().layout.selectedEmpresaId
      
      const filter = {
        where: {
          and: []
        }
      }
      
      // Siempre incluimos estos filtros ya que tienen valores por defecto
      filter.where.and.push({ skuProducto: { like: skuProducto ? `%${skuProducto}%` : '%%' } })
      
      // Solo incluimos estos filtros si tienen valores proporcionados
      if (tipoDeRango) {
        filter.where.and.push({ rangoId: { eq: `${tipoDeRango}` } })
      }
      if (club) {
        filter.where.and.push({ clubId: { eq: `${club}` } })
      }

      // Aquí añadimos la lógica para el filtro de las fechas
      if (desdeFecha && hastaFecha) {
        filter.where.and.push({ desdeFecha: { gte: desdeFecha } })
        filter.where.and.push({ hastaFecha: { lte: hastaFecha } })
      } else {
        if (desdeFecha) {
          filter.where.and.push({ desdeFecha: { eq: `${desdeFecha}` } }) // Fecha igual a `desdeFecha`
          // filter.where.and.push({ desdeFecha: { gte: desdeFecha } }) // Fecha mayor o igual a `desdeFecha`
        }
        if (hastaFecha) {
          filter.where.and.push({ hastaFecha: { eq: `${hastaFecha}` } }) // Fecha igual a `hastaFecha`
          // filter.where.and.push({ hastaFecha: { lte: hastaFecha } }) // Fecha menor o igual a `hastaFecha`
        }
      }

      const {data: dataProductoPorRango} = await apiProductosPorRango.productosPorRangoControllerFind(JSON.stringify(filter))
      const registrosPaginados = dataProductoPorRango.slice(inicio, fin) // -> Filtra pagina

      dispatch({
        type: GET_PRODUCTOS_POR_RANGO,
        productosPorRango: registrosPaginados,
        total: dataProductoPorRango.length || 0
      })

      //Guardamos los filtros de busqueda (Inputs)
      dispatch({
        type: GET_FILTROS_PRODUCTOS_POR_RANGO,
        filtros: filtrosBusqueda
    })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const addProductoPorRango = (productoPorRango, muestroMensaje, intl) => {
  return async (dispatch) => {
    try {
      const {data} = await apiProductosPorRango.productosPorRangoControllerCreate(productoPorRango)
      if (muestroMensaje) {
        displaySuccessMsg(intl.formatMessage({ id: 'Producto por rango creado' }))
      }
      dispatch({
         type: ADD_PRODUCTO_POR_RANGO,
         data
       })
      return data
    } catch (err) {
      console.error(err.message)
      console.log(err.message)
    }
  }
}

export const updateProductoPorRango = (id, productoPorRango, muestroMensaje, intl) => {
  return async (dispatch) => {
    try {
      const {data} = await apiProductosPorRango.productosPorRangoControllerUpdateById(id, productoPorRango)
      if (muestroMensaje) {
        displaySuccessMsg(intl.formatMessage({ id: 'Producto por rango actualizado' }))
      }
      
      dispatch({
         type: UPDATE_PRODUCTO_POR_RANGO,
         data
       })
       return data
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const deleteProductoPorRango = (productoPorRangoId, intl, datosRegistro) => {
  return async (dispatch) => {
    try {
      const {data} = await apiProductosPorRango.productosPorRangoControllerDeleteById(productoPorRangoId, datosRegistro.skuProducto)
      displaySuccessMsg(intl.formatMessage({ id: 'Producto por rango borrado' }))
       dispatch({
         type: DELETE_PRODUCTO_POR_RANGO,
         data: productoPorRangoId
       })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const getProductoPorRango = (productoPorRangoId) => {
  return async (dispatch) => {
    try {
      const {data: dataProductoPorRango} = await apiProductosPorRango.productosPorRangoControllerFindById(productoPorRangoId)
      dispatch({
        type: GET_PRODUCTO_POR_RANGO,
        selectedProductoPorRango: dataProductoPorRango
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const initProductoPorRango = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_PRODUCTO_POR_RANGO,
      selectedCompany: null
    })
  }
}

export const obtenerSkuPrincipales = () => {
  return async () => {
    try {
      const {data: listaSKUs} = await apiSutitutos.sustitutosControllerObtenerSkuPrincipales()
      return listaSKUs
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const obtenerTiposDeRango = () => {
  return async () => {
    try {
      const {data: listaTiposDeRango} = await apiTiposRango.tiposRangoControllerFind()
      return listaTiposDeRango
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const obtenerClubs = () => {
  return async () => {
    try {
      const {data: listaDeClubs} = await apiClubs.clubsControllerFind()
      return listaDeClubs
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const productoExisteEnRangoDeFechas = (objProductoPorRango) => {
  // Extraer solo la fecha (primeros 10 caracteres) //-> Ejm: '2024-09-12'
  const fechaInicio = objProductoPorRango.desdeFecha.substring(0, 10)
  const fechaFin = objProductoPorRango.hastaFecha.substring(0, 10)
  return async () => {
    try {
      // Buscamos que el producto no este siendo usado en esas mismas fechas
      const filter = {
        where: {
          skuProducto: objProductoPorRango.skuProducto,
          and: [
            { desdeFecha: { lte: fechaFin } },  // desdeFecha <= fechaFin nueva
            { hastaFecha: { gte: fechaInicio } }   // hastaFecha >= fechaInicio nueva
          ]
        }
      }

      return await apiProductosPorRango.productosPorRangoControllerFind(JSON.stringify(filter)).then(response => {
        return response.data
      })

    } catch (err) {
      console.error(err.message)
    }
  }
}

// S I N C R O N I Z A - C O N - B C
export const insertOupdateEnBCProductoPorRangoDeFechas = (registro) => {
  return async () => {
    try {
      const { club, sku, desdeFecha, hastaFecha, rango } = registro
      // Extraer solo la fecha (primeros 10 caracteres) //-> Ejm: '2024-09-12'
      const fechaInicio = desdeFecha.substring(0, 10)
      const fechaFin = hastaFecha.substring(0, 10)

      const dataJson = {Acction: "INSERT", items: []} // Acction: "INSERT" = Inserta o Actualiza
      const dataP = {
        CLUB: club,
        SKU: sku,
        DesdeFecha: fechaInicio,
        HastaFecha: fechaFin,
        Nivel: rango
      }
      dataJson.items.push(dataP)

      const objJSON = JSON.stringify(dataJson)
      const objJSONCambiado = objJSON.replace(/''/g, "")

      return await apiNavisionNew.conexionNavisionControllerPostEndpointBC("PIMIntegration_ItemClubItems", objJSONCambiado)
      
    } catch (err) {
      console.error(err.message)
      return err.message
    }
  }
}

// E L I M I N A - D E - B C
export const eliminarDeBCProductoPorRangoDeFechas = (registro) => {
  return async () => {
    try {
      const { club, sku, desdeFecha } = registro
      // Extraer solo la fecha (primeros 10 caracteres) //-> Ejm: '2024-09-12'
      const fechaInicio = desdeFecha.substring(0, 10)

      const dataJson = {Acction: "DELETE", items: []} // Acction: "DELETE" = Elimina el producto por rango de fechas en BC
      const dataP = {
        CLUB: club,
        SKU: sku,
        DesdeFecha: fechaInicio
      }
      dataJson.items.push(dataP)

      const objJSON = JSON.stringify(dataJson)
      const objJSONCambiado = objJSON.replace(/''/g, "")

      return await apiNavisionNew.conexionNavisionControllerPostEndpointBC("PIMIntegration_ItemClubItems", objJSONCambiado)
      
    } catch (err) {
      console.error(err.message)
      return err.message
    }
  }
}