import { TiposRangoControllerApi, settings} from '@api/backend'
import { displaySuccessMsg } from '@src/utility/Utils'

const prefix = 'tiposrango'

export const GET_TIPO_RANGO = `${prefix}/GET_DATA_tiposrango`
export const GET_TIPOS_RANGO = `${prefix}/GET_DATA`
export const GET_TIPOS_RANGO_ALL = `${prefix}/GET_DATA_ALL`
export const GET_TIPOS_RANGO_COUNT = `${prefix}/GET_DATA_COUNT`
export const ADD_TIPO_RANGO = `${prefix}/ADD`
export const DELETE_TIPO_RANGO = `${prefix}/DELETE`
export const UPDATE_TIPO_RANGO = `${prefix}/UPDATE`
export const GET_FILTROS_TIPOS_RANGO = `${prefix}/GET_FILTROS_TIPOS_RANGO`
export const INIT_DATA_NECESARIA = `${prefix}/INIT_DATA_NECESARIA`

const apiTiposRango = new TiposRangoControllerApi(settings)

export const getTiposRango = params => {
  return async (dispatch, getState) => {
    try {
      const { page = 1, perPage = 10, filtrosBusqueda } = params
      const { rango } = filtrosBusqueda || {}
      const inicio = ((page - 1) * perPage)
      const fin = inicio + perPage

      // const empresaId = getState().layout.selectedEmpresaId
      
      const filter = {
        where: {
          and: []
        }
      }
      
      // Siempre incluimos estos filtros ya que tienen valores por defecto
      filter.where.and.push({ rango: { like: rango ? `%${rango}%` : '%%' } })

      const {data: dataTiposRango} = await apiTiposRango.tiposRangoControllerFind(JSON.stringify(filter))
      const registrosPaginados = dataTiposRango.slice(inicio, fin) // -> Filtra pagina

      dispatch({
        type: GET_TIPOS_RANGO,
        tiposrango: registrosPaginados,
        total: dataTiposRango.length || 0
      })

      //Guardamos los filtros de busqueda (Inputs)
      dispatch({
        type: GET_FILTROS_TIPOS_RANGO,
        filtros: filtrosBusqueda
    })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const addTipoRango = (tipoRango, intl) => {
  return async (dispatch) => {
    try {
      const {data} = await apiTiposRango.tiposRangoControllerCreate(tipoRango)
      displaySuccessMsg(intl.formatMessage({ id: 'Tipo de rango creado' }))
      dispatch({
         type: ADD_TIPO_RANGO,
         data
       })
    } catch (err) {
      console.error(err.message)
      console.log(err.message)
    }
  }
}

export const updateTipoRango = (id, tipoRango, intl) => {
  return async (dispatch) => {
    try {
      const {data} = await apiTiposRango.tiposRangoControllerUpdateById(id, tipoRango)
      displaySuccessMsg(intl.formatMessage({ id: 'Tipo de rango actualizado' }))
      dispatch({
         type: UPDATE_TIPO_RANGO,
         data
       })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const deleteTipoRango = (tipoRangoId, intl, datosRegistro) => {
  return async (dispatch) => {
    try {
      const {data} = await apiTiposRango.tiposRangoControllerDeleteById(tipoRangoId, datosRegistro.rango)
      displaySuccessMsg(intl.formatMessage({ id: 'Tipo de rango borrado' }))
       dispatch({
         type: DELETE_TIPO_RANGO,
         data: tipoRangoId
       })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const getTipoRango = (tipoRango) => {
  return async (dispatch) => {
    try {
      const {data: dataTiporango} = await apiTiposRango.tiposRangoControllerFindById(tipoRango)
      dispatch({
        type: GET_TIPO_RANGO,
        selectedTiporango: dataTiporango
      })
    } catch (err) {
      console.error(err.message)
    }
  }
}

export const initTipoRango = () => {
  return async (dispatch) => {
    dispatch({
      type: GET_TIPO_RANGO,
      selectedCompany: null
    })
  }
}